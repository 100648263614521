import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"
import "../../../css/typography.css"

const Button = styled.button`
    width: 130px;
    display: flex;
    align-items: center;
    background-color: #003F89;
    justify-content: center;
    color: white;
    border: none;

    a p {
        font-family: "H Bold";
        font-size: 15px;
        color: white;
    }
    a {
        text-decoration: none;
    }
    @media screen and (min-width: 1800px) {
        width: 250px;
        height: 90px;

      a p{
        font-size: 30px;
      }
    }
    @media screen and (min-width: 3500px) {
        width: 350px;
        height: 190px;

      a p{
        font-size: 40px;
      }
    }
`

const IndexPage = () => {

    return (
        <Button>
            <Link href="/golden-visa"><p>Golden Visa</p></Link>
        </Button>
    )
}

export default IndexPage
