import * as React from "react"
import Header from "./header"
import Footer from "./footer"
import styled from "styled-components"
import WhatsAppButton from "../buttons/whatsApp"
import GoldenVisaButton from "../buttons/goldenVisa"
import {Helmet} from "react-helmet";

const FloatingButtons = styled.div`
    position: fixed;
    right: 1%;
    bottom: 5%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    height: 140px;
`

const Layout = ({children}) => {
    return (
        <>
            <div style={{width: '100vw', overflowX: 'hidden'}}>
                <Header />
                    <Helmet>
                        <meta name="keywords" content="Bienes raíces españa, Bienes raíces en venta españa, Invertir bienes raíces españa, Bienes raíces navarro, Cómo invertir en bienes raíces en España, Bienes raíces Madrid España, Bienes raíces Barcelona España, Bienes inmuebles en españa, Invertir en España y obtener residencia, Comprar bienes raíces españa, Bienes raíces Marbella España, Bienes raíces Málaga España, Bienes raíces Sevilla España, Bienes raíces Valencia España, Bienes raíces Zaragoza España, golden visa españa inmobiliaria, Invertir en el extranjero "/>
                        <meta name="facebook-domain-verification" content="e6gfgcj8etuv3wdho84tnxvak95b72" />
                        <script type="text/javascript">
                            {`
                                !function(){var e="rest.happierleads.com/v3/script?clientId=qy5A7tvKUx2RBqQoojmiZk&version=3.0.0",
                                t=document.createElement("script");window.location.protocol.split(":")[0];
                                t.src="https://"+e;var c=document.getElementsByTagName("script")[0];
                                t.onload = function(){ new Happierleads.default };
                                c.parentNode.insertBefore(t,c)}();
                            `}
                        </script> 
                        <script type="text/javascript">
                            {`
                                !function(f,b,e,v,n,t,s)
                                {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
                                n.callMethod.apply(n,arguments):n.queue.push(arguments)};
                                if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
                                n.queue=[];t=b.createElement(e);t.async=!0;
                                t.src=v;s=b.getElementsByTagName(e)[0];
                                s.parentNode.insertBefore(t,s)}(window, document,'script',
                                'https://connect.facebook.net/en_US/fbevents.js');
                                fbq('init', '449662443735195');
                                fbq('track', 'PageView');
                            `}
                        </script>
                        <noscript>
                            {`
                                <img height="1" width="1" style="display:none" src="https://www.facebook.com/tr?id=449662443735195&ev=PageView&noscript=1"/>
                            `}
                        </noscript>
                        <script async src="https://www.googletagmanager.com/gtag/js?id=G-6HB2GBS6GS"></script>
                        <script type="text/javascript">
                            {`window.dataLayer = window.dataLayer || [];
                            function gtag(){dataLayer.push(arguments);}
                            gtag('js', new Date());

                            gtag('config', 'G-6HB2GBS6GS')`}
                        </script>
                        <script async src="https://www.googletagmanager.com/gtag/js?id=AW-10951415404"></script>
                        <script>
                            {`window.dataLayer = window.dataLayer || [];
                            function gtag(){dataLayer.push(arguments);}
                            gtag('js', new Date());

                            gtag('config', 'AW-10951415404')`}
                        </script>
                        <script async src="https://www.googletagmanager.com/gtag/js?id=G-6HB2GBS6GS"></script>
                        <script type="text/javascript">
                            {`
                                window.dataLayer = window.dataLayer || [];
                                function gtag(){dataLayer.push(arguments);}
                                gtag('js', new Date());
        
                                gtag('config', 'G-6HB2GBS6GS');
                            `}
                        </script>
                    </Helmet>  
                    {children}
                <Footer />
                <FloatingButtons>
                    <WhatsAppButton />
                    <GoldenVisaButton />
                </FloatingButtons>
            </div>
        </>
    )
}

export default Layout