import React, {useState} from "react"
import Logo from "../../images/layout/Logo.webp"
import { Link } from "gatsby"
import styled from "styled-components"
import "../../css/typography.css"
import useWindowSize from "../../hooks/useWindowSize"
import { MenuOutlined } from '@ant-design/icons';

const HeaderTag = styled.header`
    display: flex;
    justify-content: space-between;
    background-color: #0B2A3B;
    align-items: center;

    nav {
        display: flex;
        justify-content: space-between;
        width: 60%;
        padding: 3% 5% 2% 2%;
    }
    nav a {
        font-family: 'H Regular';
        color: white;
        text-decoration: none;
        font-size: 20px;
    }
    div img {
        width: 40%;
    }
    div{
        padding: 1% 1% 1% 5%;
    }

    @media only screen and (min-width: 1700px) {
        div img {
            width: 60%;
        }
        nav a {
            font-size: 35px;
        }
    }

    @media only screen and (min-width: 2900px) {
        div img {
            width: 80%;
        }
        nav a {
            font-size: 55px;
        }
    }
    @media only screen and (min-width: 4000px) {
        div img {
            width: 90%;
        }
        nav a {
            font-size: 65px;
        }
    }
    @media only screen and (max-width: 910px) {
        nav a {
            font-size: 15px;
        }
    }
    @media only screen and (max-width: 752px) {

        div img {
            width: 90%;
        }
        div {
            padding: 3% 3% 3% 8%;
        }
        >div:nth-child(2) {
            width: 32%;
            padding-left: 5%;
        }
    }

    

`

const MobileNav = styled.nav`
    display: flex;
    flex-direction: column;
    background-color: #0B2A3B;
    padding: 5% 0% 3% 8%;

    a {
        text-decoration: none;
        color: white;
        font-family: 'H Regular';
        font-size: 18px;
        margin-bottom: 10px;
    }
`

const Header = () => {
    const windowSize = useWindowSize();
    const [nav, setNav] = useState(false)

    return windowSize > 752 ? (
        <HeaderTag>
            <div>
                <Link to='/'>                
                    <img src={Logo} alt="logo" />
                </Link>
            </div>
            <nav>
                <Link to='/' >Inicio</Link>
                <Link to='/como-invertir-espana' >Inversionista</Link>
                <Link to='/golden-visa' >Golden Visa</Link>
                <Link to='https://portal.ingezarespana.com' >Portal Clientes</Link>
                <Link to='/contacto' >Contacto</Link>
            </nav>
        </HeaderTag>
    ) : (
        <div>
        <HeaderTag>
            <div>
                <MenuOutlined style={{color: 'white', fontSize: '280%'}} nav={nav} onClick={() => setNav(!nav)} />
            </div>
            <div>
                <Link to='/'>                
                    <img src={Logo} alt="logo" />
                </Link>            
            </div>
        </HeaderTag>
        { nav ? (
            <MobileNav>
                <Link to='/' >Inicio</Link>
                <Link to='/como-invertir-espana' >Inversionista</Link>
                <Link to='/golden-visa' >Golden Visa</Link>
                <Link to='/contacto' >Contacto</Link>
            </MobileNav>
        ) : null }
        </div>
    )
}

export default Header
