import React from "react"
import styled from "styled-components"
import "../../../css/typography.css"
import WhatsApp from "../../../images/layout/WhatsApp.webp"
import { Link } from "gatsby"

const Button = styled.button`
    width: 130px;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    background-color: #35AD2B;
    color: white;
    border: none;

    a p {
        font-family: "H Bold";
        font-size: 15px;
        color: white;
    }
    a {
        text-decoration: none;
        margin-right: 5%;
    }
    a img {
        width: 60%;
    }

    @media screen and (min-width: 1800px) {
        width: 250px;
        height: 90px;
        margin-bottom:20px;

      a p{
        font-size: 30px;
      }
      a img{
        width: 80%;
      }
    }

    @media screen and (min-width: 3500px) {
        width: 350px;
        height: 190px;
        margin-bottom:20px;

      a p{
        font-size: 50px;
      }
      a img{
        width: 80%;
      }
    }
`

const IndexPage = () => {

    return (
        <Button>
            <Link href='https://api.whatsapp.com/send?phone=5215580452327' target="_blank"><img src={WhatsApp} alt="WhatsApp Icon" /></Link>
            <Link href='https://api.whatsapp.com/send?phone=5215580452327' target="_blank"><p>WhatsApp</p></Link>
        </Button>
    )
}

export default IndexPage
